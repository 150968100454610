import { createSlice } from "@reduxjs/toolkit";

const token = sessionStorage.getItem('token');
var auth = false;

console.log('In AuthSlice token: ', token)
if(token){
    auth = true;
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: token,
        isLogin: auth,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
            state.isLogin = true;
        },
        clearToken: (state) => {
            state.token = null;
            state.isLogin = false;
        },
    },
});

export const { setToken, isLogin, clearToken } = authSlice.actions;
export default authSlice.reducer;