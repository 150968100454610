import React, { createContext, useState } from 'react';

const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [surveyData, setSurveyData] = useState({
    surveyName: sessionStorage.getItem('surveyName') ?? '',
    surveyType: sessionStorage.getItem('surveyType') ?? '',
    base64String: sessionStorage.getItem('base64String') ?? '',
    imageFileName: sessionStorage.getItem('imageFileName') ?? '',
    isTemplate: sessionStorage.getItem('isTemplate') === 'true' ? true : false,
    isPublic: sessionStorage.getItem('isPublic') === 'true' ? true : false,
});

  return (
    <SurveyContext.Provider value={{ surveyData, setSurveyData }}>
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyContext;