import './App.css';
import PageRouts from './Routes';
// import { AuthProvider } from './context/AuthProvider';
import { SurveyProvider } from './context/surveyContext';

function App() {
  return (
    <div className="App">
      {/* <AuthProvider> */}
      <SurveyProvider>
        <PageRouts />
      </SurveyProvider>
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
