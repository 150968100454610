import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

const Login = lazy(() => import('./../pages/login'));
const Signup = lazy(() => import('./../pages/signup'));
const SignUpp = lazy(() => import('../pages/signup/SignUpp.jsx'));
const CreateBot = lazy(() => import('./../pages/createbot-dashboard'));
const CreateBotInterfaceNew = lazy(() => import('../pages/create-questions'));
const AddParticipants = lazy(() => import('./../pages/add-participants'));
const CreateSurvey = lazy(() => import('./../pages/create-survey-page'));
const SurveyTemplate = lazy(() => import('./../pages/survey-templates'));
const NewHome = lazy(() => import('./../pages/new-home'));
const SurveyDescription = lazy(() => import('./../pages/survey-description'));

const PageRouts = (props) => {

  const isLogin = useSelector((state) => {
    return state.auth.isLogin
  })

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {!isLogin ?
            <>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signup-new" element={<SignUpp />} />

            </>
            :
            <>
              <Route path="/" element={<NewHome />} />
              <Route path="/createBot" element={<CreateBot />} />
              <Route path="/createbotInterface" element={<CreateBotInterfaceNew />} />
              <Route path="/add-participants" element={<AddParticipants />} />
              <Route path="/create-survey" element={<CreateSurvey />} />
              <Route path="/survey-templates" element={<SurveyTemplate />} />
              <Route path="/survey-description" element={<SurveyDescription />} />

            </>
          }
        </Routes>
      </Suspense>
    </Router>
  )
}

export default PageRouts;